import React, { Component } from "react";

export default class Footer extends Component {
	render() {
		return (
			<div>
				<footer className="footer text-center text-uppercase small py-4 text-info">
					Matthew Kartchner &copy;2019
				</footer>
			</div>
		);
	}
}
